


const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('visible');
        } else {
            entry.target.classList.remove('visible');
        }
    });
});



const hiddenElements = document.querySelectorAll('.hidden');
hiddenElements.forEach((element) => {
    observer.observe(element);
});





//  cycled animation for a series of images
var slideIndex = 0;
var slides = document.getElementsByClassName("mySlides");

// Moved some static styles to CSS to keep it clean here
showSlides();

function showSlides() {
  //Hide the previous image
  slides[slideIndex === 0 ? slides.length - 1 : slideIndex - 1].style.opacity =
    "0";
  // Show the current image
  slides[slideIndex].style.opacity = "1";
  // Add index for next cycle
  slideIndex === slides.length - 1 ? (slideIndex = 0) : slideIndex++;
  // Set delay for next cycle
  setTimeout(showSlides, 250);
}






// sign up form

const targetDiv = document.getElementById("modal");
const btn = document.getElementById("open-modal");
const close = document.getElementById("close-modal");
btn.onclick = function () {

    // targetDiv.style.display = "block";
    targetDiv.style.opacity = "1";
    targetDiv.style.display = "block";
};
close.onclick = function () {
    targetDiv.style.opacity = "0";
    targetDiv.style.display = "none";
}
window.onclick = function (event) {
    if (event.target == targetDiv) {
        targetDiv.style.opacity = "0";
        targetDiv.style.display = "none";
    }
}



// // global variable for the player
// var player;

// // this function gets called when API is ready to use
// function onYouTubePlayerAPIReady() {
//     // create the global player from the specific iframe (#video)
//     player = new YT.Player('video', {
//         events: {
//             // call this function when player is ready to use
//             'onReady': onPlayerReady
//         }
//     });
// }

// function onPlayerReady(event) {

//     const youtube = document.getElementById("youtube-modal");
//     // bind events
//     var playButton = document.getElementById("play-button");
//     playButton.addEventListener("click", function() {
//         player.playVideo();
//         youtube.style.opacity = "1";
//         youtube.style.display = "block";
//     });

//     var pauseButton = document.getElementById("pause-button");
//     pauseButton.addEventListener("click", function() {
//         player.pauseVideo();
//         youtube.style.opacity = "0";
//       youtube.style.display = "none";
//     });

//     window.onclick = function (event) {
//     if (event.target == youtube) {
//         player.pauseVideo();
//       youtube.style.opacity = "0";
//         youtube.style.display = "none";
//     }
// }


//     var stopButton = document.getElementById("stop-button");
//     stopButton.addEventListener("click", function() {
//         player.stopVideo();
//     });

// }

// // Inject YouTube API script
// var tag = document.createElement('script');
// tag.src = "https://www.youtube.com/player_api";
// var firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);









